import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import {thunk} from "redux-thunk";
import { authReducer } from "./reducers/indexReducer";






export const store = createStore(
    combineReducers(
        {
            auth: authReducer,
        }
    ),
    compose(
        applyMiddleware(thunk),
        /*window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()*/
    )
);