import React, { useState } from 'react';
import {
  Container,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  TextField,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  IconButton,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { initialRecipes } from '../init/initialRecipes';

const RecipeListPage = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredRecipes, setFilteredRecipes] = useState(initialRecipes);
  const [open, setOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [newRecipe, setNewRecipe] = useState({
    libelle: '',
    taux: '',
    prestataire: '',
    repartitionPrestataire: '',
    repartitionEtat: '',
    secteur: '',
  });
  const [prestataires] = useState([
    // ... (Liste des prestataires)
  ]);
  const [secteurs] = useState([
    // ... (Liste des secteurs)
  ]);

  
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = (event) => {
    const search = event.target.value.toLowerCase();
    setSearchTerm(search);
    setFilteredRecipes(
      initialRecipes.filter(recipe =>
        recipe.libelle.toLowerCase().includes(search) ||
        recipe.taux.toLowerCase().includes(search) ||
        recipe.prestataire.toLowerCase().includes(search) ||
        recipe.repartition.toLowerCase().includes(search) ||
        recipe.secteur.toLowerCase().includes(search)
      )
    );
  };

  const handleClickOpen = () => {
    setEditMode(false);
    setOpen(true);
  };

  const handleClickEdit = (index) => {
    setEditMode(true);
    setCurrentIndex(index);
    setNewRecipe(filteredRecipes[index]);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setNewRecipe(prevRecipe => {
      const updatedRecipe = {
        ...prevRecipe,
        [name]: value
      };

      // Mise à jour automatique de la répartition de l'autre partie
      if (name === 'repartitionPrestataire') {
        updatedRecipe.repartitionEtat = (100 - parseFloat(value)).toFixed(2);
      } else if (name === 'repartitionEtat') {
        updatedRecipe.repartitionPrestataire = (100 - parseFloat(value)).toFixed(2);
      }

      return updatedRecipe;
    });
  };

  const handleSave = () => {
    if (newRecipe.repartitionPrestataire && newRecipe.repartitionEtat) {
      const totalRepartition = parseFloat(newRecipe.repartitionPrestataire) + parseFloat(newRecipe.repartitionEtat);
      if (totalRepartition === 100) {
        if (editMode) {
          // Mise à jour de la recette existante
          const updatedRecipes = [...filteredRecipes];
          updatedRecipes[currentIndex] = {
            ...newRecipe,
            repartition: `${newRecipe.repartitionPrestataire}% Prestataire, ${newRecipe.repartitionEtat}% État`
          };
          setFilteredRecipes(updatedRecipes);
        } else {
          // Ajout d'une nouvelle recette
          setFilteredRecipes([...filteredRecipes, {
            ...newRecipe,
            repartition: `${newRecipe.repartitionPrestataire}% Prestataire, ${newRecipe.repartitionEtat}% État`
          }]);
        }
        setNewRecipe({
          libelle: '',
          taux: '',
          prestataire: '',
          repartitionPrestataire: '',
          repartitionEtat: '',
          secteur: '',
        });
        setOpen(false);
      } else {
        alert('La somme des répartitions doit être égale à 100%.');
      }
    } else {
      alert('Veuillez remplir toutes les informations.');
    }
  };

  const handleDelete = (index) => {
    const updatedRecipes = filteredRecipes.filter((_, i) => i !== index);
    setFilteredRecipes(updatedRecipes);
  };

  return (
    <Container>
      <Typography variant="h6" gutterBottom>
        Liste des Recettes
      </Typography>
      <Button
        variant="contained"
        color="primary"
        startIcon={<AddIcon />}
        onClick={handleClickOpen}
        style={{ marginBottom: '16px' }}
      >
        Ajouter
      </Button>
      <TextField
        variant="outlined"
        margin="normal"
        fullWidth
        size='small'
        label="Rechercher"
        value={searchTerm}
        onChange={handleSearch}
      />
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow style={{ fontWeight: 'bold', textTransform: 'uppercase' }}>
              <TableCell>Libellé</TableCell>
              <TableCell>Taux(CDF)</TableCell>
              <TableCell>Prestataire</TableCell>
              <TableCell>Clé Répartition</TableCell>
              <TableCell>Secteur</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredRecipes
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((recipe, index) => (
                <TableRow key={index}>
                  <TableCell>{recipe.libelle}</TableCell>
                  <TableCell>{recipe.taux}</TableCell>
                  <TableCell>{recipe.prestataire}</TableCell>
                  <TableCell>{recipe.repartition}</TableCell>
                  <TableCell>{recipe.secteur}</TableCell>
                  <TableCell>
                    <IconButton color="primary" onClick={() => handleClickEdit(index)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton color="secondary" onClick={() => handleDelete(index)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={filteredRecipes.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{editMode ? 'Modifier la Recette' : 'Ajouter une Nouvelle Recette'}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                autoFocus
                margin="dense"
                name="libelle"
                label="Libellé"
                type="text"
                fullWidth
                variant="outlined"
                value={newRecipe.libelle}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                margin="dense"
                name="taux"
                label="Taux (CDF)"
                type="number"
                fullWidth
                variant="outlined"
                value={newRecipe.taux}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth variant="outlined" margin="dense">
                <InputLabel>Prestataire</InputLabel>
                <Select
                  name="prestataire"
                  value={newRecipe.prestataire}
                  onChange={handleChange}
                  label="Prestataire"
                >
                  {prestataires.map((prestataire, index) => (
                    <MenuItem key={index} value={prestataire}>
                      {prestataire}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                margin="dense"
                name="repartitionPrestataire"
                label="Répartition Prestataire (%)"
                type="number"
                fullWidth
                variant="outlined"
                value={newRecipe.repartitionPrestataire}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                margin="dense"
                name="repartitionEtat"
                label="Répartition État (%)"
                type="number"
                fullWidth
                variant="outlined"
                value={newRecipe.repartitionEtat}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth variant="outlined" margin="dense">
                <InputLabel>Secteur</InputLabel>
                <Select
                  name="secteur"
                  value={newRecipe.secteur}
                  onChange={handleChange}
                  label="Secteur"
                >
                  {secteurs.map((secteur, index) => (
                    <MenuItem key={index} value={secteur}>
                      {secteur}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Annuler
          </Button>
          <Button onClick={handleSave} color="primary">
            {editMode ? 'Sauvegarder' : 'Ajouter'}
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default RecipeListPage;
