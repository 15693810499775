import * as React from 'react';
import SectorList from './list/SectorList';

 const Sector = ()=>{

  return (
    <SectorList/>
  );
}

export default Sector;