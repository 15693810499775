import * as React from 'react';
import SiteList from './list/SiteList';

 const Sector = ()=>{

  return (
    <SiteList/>
  );
}

export default SiteList;