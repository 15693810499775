import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination, Paper, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, MenuItem, Select, InputLabel, FormControl, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import VisibilityIcon from '@mui/icons-material/Visibility';
const users = [
  // Remplissez avec des données utilisateur
];

const companies = [
  // Remplissez avec des données d'entreprises
];

const UserManagement = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);  
  const [open, setOpen] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [usersData, setUsersData] = React.useState([]);
  const [formData, setFormData] = useState({
    nom: '',
    postNom: '',
    prenom: '',
    email: '',
    company: '',
    telephone: '',
  });

  const handleClickOpen = (user = null) => {
    setCurrentUser(user);
    setFormData(user || {
      nom: '',
      postNom: '',
      prenom: '',
      email: '',
      company: '',
      telephone: '',
    });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSave = () => {
    // Implémentez ici la logique de sauvegarde (ajout ou mise à jour)
    if (currentUser) {
      // Mise à jour
      console.log('Mettre à jour l\'utilisateur', formData);
    } else {
      // Ajout
      console.log('Ajouter un nouvel utilisateur', formData);
    }
    handleClose();
  };

  const handleDelete = (userId) => {
    // Implémentez ici la logique de suppression
    console.log('Supprimer l\'utilisateur avec l\'ID', userId);
  };

  
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  
  React.useEffect(()=>{
    (async()=>{
      try {
        const response = await fetch("https://artefnetwork.com/api/users");
      if(response.ok){
        const {data} = await response.json();
        let formatedData = data.map((item, index)=>{
          return {
            nom: item.name,
            postNom: '',
            prenom: '',
            email: item.email,
            company: 'Compagnie '.concat(index),
            telephone: item.phone,
          }
        });

        setUsersData(formatedData);
      }
      } catch (error) {
        
      }
    }) ();
  }, []);


  return (
    <div>
        <Button
        variant="contained"
        color="primary"
        endIcon={<AddIcon />}
        onClick={handleClickOpen}
        style={{ marginBottom: '16px' }}
      >
        Ajouter
      </Button>

      <TableContainer component={Paper} style={{ marginTop: '16px' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Nom</TableCell>
              {/*<TableCell>Post-Nom</TableCell>
              <TableCell>Prénom</TableCell>*/}
              <TableCell>Email</TableCell>
              <TableCell>Entreprise</TableCell>
              <TableCell>Téléphone</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
            usersData
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((user, index) => (
              <TableRow key={user.id}>
                <TableCell style={{paddingTop: '0px', paddingBottom: '0px'}}>{user.nom}</TableCell>
                {/*<TableCell style={{paddingTop: '0px', paddingBottom: '0px'}}>{user.postNom}</TableCell>
                <TableCell style={{paddingTop: '0px', paddingBottom: '0px'}}>{user.prenom}</TableCell>*/}
                <TableCell style={{paddingTop: '0px', paddingBottom: '0px'}}>{user.email}</TableCell>
                <TableCell style={{paddingTop: '0px', paddingBottom: '0px'}}>{user.company}</TableCell>
                <TableCell style={{paddingTop: '0px', paddingBottom: '0px'}}>{user.telephone}</TableCell>
                <TableCell style={{paddingTop: '0px', paddingBottom: '0px'}}>
                <IconButton onClick={() => handleClickOpen(index)}>
                   <VisibilityIcon />
                </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={usersData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />


      <Dialog open={open} onClose={handleClose}>
        <form method='POST'>
        <DialogTitle>{currentUser ? 'Modifier l\'utilisateur' : 'Ajouter un utilisateur'}</DialogTitle>
        <DialogContent>
          <TextField
            name="nom"
            label="Nom"
            value={formData.nom}
            onChange={handleChange}
            fullWidth
            size='small'
            margin="normal"
            required
          />
          <TextField
            name="postNom"
            label="Post-Nom"
            value={formData.postNom}
            onChange={handleChange}
            fullWidth
             size='small'
            margin="normal"
            required
          />
          <TextField
            name="prenom"
            label="Prénom"
            value={formData.prenom}
            onChange={handleChange}
            fullWidth
             size='small'
            margin="normal"
            required
          />
          <TextField
            name="email"
            label="Email"
            value={formData.email}
            onChange={handleChange}
            fullWidth
            size='small'
            margin="normal"
            required
          />
          <TextField
            name="telephone"
            label="Téléphone"
            value={formData.telephone}
            onChange={handleChange}
            fullWidth
             size='small'
            margin="normal"
            required
          />
          <FormControl fullWidth margin="normal">
            <InputLabel>Entreprise</InputLabel>
            <Select
              name="company"
              value={formData.company}
              onChange={handleChange}
               size='small'
            >
              {companies.map((company) => (
                <MenuItem key={company.id} value={company.name}>
                  {company.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Annuler</Button>
          <Button type='submit' onClick={handleSave} color="primary">
            {currentUser ? 'Sauvegarder' : 'Ajouter'}
          </Button>
        </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default UserManagement;
