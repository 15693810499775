export const initialSectors = [
    { nom: 'Environnement', code: 'ENV001' },
    { nom: 'Finances', code: 'FIN002' },
    { nom: 'Habitat', code: 'HAB003' },
    { nom: 'Éducation', code: 'EDU004' },
    { nom: 'Santé', code: 'SAN005' },
    { nom: 'Transport', code: 'TRA006' },
    { nom: 'Agriculture', code: 'AGR007' },
    { nom: 'Culture', code: 'CUL008' },
    { nom: 'Tourisme', code: 'TOU009' },
    { nom: 'Industrie', code: 'IND010' },
    { nom: 'Commerce', code: 'COM011' },
    { nom: 'Infrastructures', code: 'INF012' },
    { nom: 'Eau', code: 'EAU013' },
    { nom: 'Énergie', code: 'ENE014' },
    { nom: 'Logement', code: 'LOG015' },
    { nom: 'Sécurité', code: 'SEC016' },
    { nom: 'Justice', code: 'JUS017' },
    { nom: 'Affaires étrangères', code: 'AFE018' },
    { nom: 'Affaires intérieures', code: 'AFI019' },
    { nom: 'Relations internationales', code: 'REL020' },
    { nom: 'Recherche', code: 'REC021' },
  ];