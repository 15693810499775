export const initialRecipes = [
    { libelle: 'Recette A', taux: '5600', prestataire: 'Prestataire X', repartition: '50% Prestataire, 50% État', secteur: 'Environnement' },
    { libelle: 'Recette B', taux: '8000', prestataire: 'Prestataire Y', repartition: '60% Prestataire, 40% État', secteur: 'Finances' },
    { libelle: 'Recette C', taux: '3500', prestataire: 'Prestataire Z', repartition: '55% Prestataire, 45% État', secteur: 'Habitat' },
    { libelle: 'Recette D', taux: '25000', prestataire: 'Prestataire A', repartition: '70% Prestataire, 30% État', secteur: 'Environnement' },
    { libelle: 'Recette E', taux: '10000', prestataire: 'Prestataire B', repartition: '40% Prestataire, 60% État', secteur: 'Finances' },
    { libelle: 'Recette F', taux: '11000', prestataire: 'Prestataire C', repartition: '65% Prestataire, 35% État', secteur: 'Habitat' },
    { libelle: 'Recette G', taux: '12000', prestataire: 'Prestataire D', repartition: '50% Prestataire, 50% État', secteur: 'Environnement' },
    { libelle: 'Recette H', taux: '13000', prestataire: 'Prestataire E', repartition: '60% Prestataire, 40% État', secteur: 'Finances' },
    { libelle: 'Recette I', taux: '11000', prestataire: 'Prestataire F', repartition: '55% Prestataire, 45% État', secteur: 'Habitat' },
    { libelle: 'Recette J', taux: '1700', prestataire: 'Prestataire G', repartition: '75% Prestataire, 25% État', secteur: 'Environnement' },
    { libelle: 'Recette K', taux: '9200', prestataire: 'Prestataire H', repartition: '45% Prestataire, 55% État', secteur: 'Environnement' },
    { libelle: 'Recette L', taux: '3500', prestataire: 'Prestataire I', repartition: '50% Prestataire, 50% État', secteur: 'Finances' },
    { libelle: 'Recette M', taux: '3000', prestataire: 'Prestataire J', repartition: '65% Prestataire, 35% État', secteur: 'Habitat' },
    { libelle: 'Recette N', taux: '15000', prestataire: 'Prestataire K', repartition: '55% Prestataire, 45% État', secteur: 'Environnement' },
    { libelle: 'Recette O', taux: '22000', prestataire: 'Prestataire L', repartition: '40% Prestataire, 60% État', secteur: 'Finances' },
    { libelle: 'Recette P', taux: '7500', prestataire: 'Prestataire M', repartition: '70% Prestataire, 30% État', secteur: 'Habitat' },
    { libelle: 'Recette Q', taux: '9600', prestataire: 'Prestataire N', repartition: '50% Prestataire, 50% État', secteur: 'Environnement' },
    { libelle: 'Recette R', taux: '8000', prestataire: 'Prestataire O', repartition: '60% Prestataire, 40% État', secteur: 'Finances' },
    { libelle: 'Recette S', taux: '13000', prestataire: 'Prestataire P', repartition: '55% Prestataire, 45% État', secteur: 'Habitat' },
    { libelle: 'Recette T', taux: '2100', prestataire: 'Prestataire Q', repartition: '80% Prestataire, 20% État', secteur: 'Environnement' },
  ];