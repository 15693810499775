import React, { useState } from 'react';
import {
  Box, Button, TextField, MenuItem, Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow, Paper, InputAdornment, IconButton,
  Popover
} from '@mui/material';
import { DateRange } from 'react-date-range';
import { addMonths, format, subDays } from 'date-fns';
import { fr } from 'date-fns/locale';
import 'react-date-range/dist/styles.css'; // Import styles
import 'react-date-range/dist/theme/default.css'; // Import theme styles
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';

const sites = ['MOUKONDO', 'NGIRI-NGIRI', 'KASA-VUBU'];
const prestations = ['Carte Grise', 'Permis de conduire'];
const partenaires = ['Weblinx', 'Gimov 242'];

const rows = [
  { id: 1, date: '2024-03-14', prestation: 'Carte Grise', paiement: 500000, site: 'MOUKONDO', intitule: 'KOUKAYE JOSIAN', utilisateur: 'Orphée Lié', partenaire: 'Weblinx' },
  { id: 2, date: '2024-03-13', prestation: 'Carte Grise', paiement: 500000, site: 'MOUKONDO', intitule: 'EMMANUEL MACRON', utilisateur: 'Orphée Lié', partenaire: 'Weblinx' },
  // Ajoute d'autres lignes ici...
];

function MyTable() {
  const [dateRange, setDateRange] = useState([{ startDate: new Date(), endDate: new Date(), key: 'selection' }]);
  const [selectedSite, setSelectedSite] = useState('');
  const [selectedPrestation, setSelectedPrestation] = useState('');
  const [selectedPartenaire, setSelectedPartenaire] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [anchorEl, setAnchorEl] = useState(null); // Pour gérer l'ouverture du Popover

  const handleClearFilters = () => {
    setDateRange([{ startDate: new Date(), endDate: new Date(), key: 'selection' }]);
    setSelectedSite('');
    setSelectedPrestation('');
    setSelectedPartenaire('');
    setSearchTerm('');
  };

  const handleOpenDateRange = (event) => {
    setAnchorEl(event.currentTarget); // Ouvre le Popover
  };

  const handleCloseDateRange = () => {
    setAnchorEl(null); // Ferme le Popover
  };

  const open = Boolean(anchorEl); // Vérifie si le Popover doit être ouvert
  const id = open ? 'date-range-popover' : undefined;

  return (
    <>
        <Box>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
            <TextField
            label="Sélectionner la période"
            value={`${format(dateRange[0].startDate, 'dd/MM/yyyy')} - ${format(dateRange[0].endDate, 'dd/MM/yyyy')}`}
            onClick={handleOpenDateRange}
            size="small"
            />
            <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleCloseDateRange}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            >
            <DateRange
                editableDateInputs={true}
                onChange={item => setDateRange([item.selection])}
                moveRangeOnFirstSelection={false}
                ranges={dateRange}
                locale={fr}
            />
            </Popover>
            
            <TextField
            select
            label="Site"
            value={selectedSite}
            onChange={(e) => setSelectedSite(e.target.value)}
            size="small"
            >
            {sites.map((site) => (
                <MenuItem key={site} value={site}>
                {site}
                </MenuItem>
            ))}
            </TextField>
            <TextField
            select
            label="Prestation"
            value={selectedPrestation}
            onChange={(e) => setSelectedPrestation(e.target.value)}
            size="small"
            >
            {prestations.map((prestation) => (
                <MenuItem key={prestation} value={prestation}>
                {prestation}
                </MenuItem>
            ))}
            </TextField>
            <TextField
            select
            label="Partenaires"
            value={selectedPartenaire}
            onChange={(e) => setSelectedPartenaire(e.target.value)}
            size="small"
            >
            {partenaires.map((partenaire) => (
                <MenuItem key={partenaire} value={partenaire}>
                {partenaire}
                </MenuItem>
            ))}
            </TextField>
            <Button variant="contained" color="primary" onClick={handleClearFilters}>
            Effacez les filtres
            </Button>
            </Box>
        </Box>
        <Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end',gap: 2, mb: 2 }}>
                
                <TextField
                placeholder="Rechercher"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                InputProps={{
                    endAdornment: (
                    <InputAdornment position="end">
                        <IconButton onClick={() => setSearchTerm('')}>
                        <ClearIcon />
                        </IconButton>
                        <IconButton>
                        <SearchIcon />
                        </IconButton>
                    </InputAdornment>
                    ),
                }}
                size="small"
                />
            </Box>

            <TableContainer component={Paper}>
                <Table>
                <TableHead>
                    <TableRow>
                    <TableCell>#</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell>Prestation</TableCell>
                    <TableCell>Paiement</TableCell>
                    <TableCell>Site</TableCell>
                    <TableCell>Intitulé</TableCell>
                    <TableCell>Utilisateurs</TableCell>
                    <TableCell>Partenaire</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row) => (
                    <TableRow key={row.id}>
                        <TableCell>{row.id}</TableCell>
                        <TableCell>{row.date}</TableCell>
                        <TableCell>{row.prestation}</TableCell>
                        <TableCell>{row.paiement}</TableCell>
                        <TableCell>{row.site}</TableCell>
                        <TableCell>{row.intitule}</TableCell>
                        <TableCell>{row.utilisateur}</TableCell>
                        <TableCell>{row.partenaire}</TableCell>
                    </TableRow>
                    ))}
                </TableBody>
                </Table>
            </TableContainer>
        </Box>
    </>
  );
}

export default MyTable;
