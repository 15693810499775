import * as React from 'react';
import StatisticsIndex from './stats/StatisticsIndex';

 const Statistic = ()=>{

  return (
    <StatisticsIndex/>
  );
}

export default Statistic;