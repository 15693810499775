import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { Card, CardContent, Typography } from '@mui/material';
import L from 'leaflet';

// Coordonnées des communes de Kinshasa
// Coordonnées des 24 communes de Kinshasa
const locations = [
    { lat: -4.319, lng: 15.319, name: 'Gombe' },
    { lat: -4.326, lng: 15.308, name: 'Lingwala' },
    { lat: -4.331, lng: 15.315, name: 'Kasa-Vubu' },
    { lat: -4.328, lng: 15.325, name: 'Kalamu' },
    { lat: -4.332, lng: 15.320, name: 'Ngiri-Ngiri' },
    { lat: -4.313, lng: 15.293, name: 'Masina' },
    { lat: -4.306, lng: 15.289, name: 'Bandalungwa' },
    { lat: -4.311, lng: 15.304, name: 'Barumbu' },
    { lat: -4.317, lng: 15.322, name: 'Bumbu' },
    { lat: -4.312, lng: 15.310, name: 'Kimbanseke' },
    { lat: -4.320, lng: 15.290, name: 'Kinshasa' },
    { lat: -4.324, lng: 15.308, name: 'Kintambo' },
    { lat: -4.310, lng: 15.323, name: 'Kisenso' },
    { lat: -4.319, lng: 15.336, name: 'Lemba' },
    { lat: -4.329, lng: 15.293, name: 'Limete' },
    { lat: -4.320, lng: 15.307, name: 'Makala' },
    { lat: -4.340, lng: 15.330, name: 'Maluku' },
    { lat: -4.315, lng: 15.314, name: 'Matete' },
    { lat: -4.335, lng: 15.310, name: 'Mont-Ngafula' },
    { lat: -4.320, lng: 15.321, name: 'Ndjili' },
    { lat: -4.328, lng: 15.319, name: 'Ngaba' },
    { lat: -4.312, lng: 15.329, name: 'Ngaliema' },
    { lat: -4.310, lng: 15.308, name: 'Ngiri-Ngiri' },
    { lat: -4.330, lng: 15.303, name: 'Nsele' },
    { lat: -4.318, lng: 15.328, name: 'Selembao' }
  ];
  

// SVG pour l'icône de localisation, agrandi à 48x48 pixels
const svgIcon = `
  <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 256 256">
    <g transform="translate(1.4065934065934016 1.4065934065934016) scale(0.75 0.75)">
      <path d="M 45 90 c -1.415 0 -2.725 -0.748 -3.444 -1.966 l -4.385 -7.417 C 28.167 65.396 19.664 51.02 16.759 45.189 c -2.112 -4.331 -3.175 -8.955 -3.175 -13.773 C 13.584 14.093 27.677 0 45 0 c 17.323 0 31.416 14.093 31.416 31.416 c 0 4.815 -1.063 9.438 -3.157 13.741 c -0.025 0.052 -0.053 0.104 -0.08 0.155 c -2.961 5.909 -11.41 20.193 -20.353 35.309 l -4.382 7.413 C 47.725 89.252 46.415 90 45 90 z" style="fill: rgb(4,136,219);" />
      <path d="M 45 45.678 c -8.474 0 -15.369 -6.894 -15.369 -15.368 S 36.526 14.941 45 14.941 c 8.474 0 15.368 6.895 15.368 15.369 S 53.474 45.678 45 45.678 z" style="fill: rgb(255,255,255);" />
    </g>
  </svg>
`;

// Fonction pour créer une icône personnalisée avec SVG
const createCustomIcon = () => {
  return new L.DivIcon({
    className: 'custom-icon',
    html: svgIcon,
    iconSize: [48, 48], // Taille de l'icône
    iconAnchor: [24, 48], // Ancrage de l'icône
  });
};

const MapComponent = () => (
  <Card>
    <CardContent>
      <Typography variant="h6" gutterBottom>
        Carte des Communes de Kinshasa
      </Typography>
      <MapContainer center={[-4.321, 15.308]} zoom={12} style={{ height: '400px', width: '100%' }}>
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        {locations.map((location, index) => (
          <Marker key={index} position={[location.lat, location.lng]} icon={createCustomIcon()}>
            <Popup>{location.name}</Popup>
          </Marker>
        ))}
      </MapContainer>
    </CardContent>
  </Card>
);

export default MapComponent;
