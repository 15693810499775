import React, { useState } from 'react';
import {
  Container,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const initialSites = [
  { nom: 'Site A', code: 'A001', long: '15.987', lat: '-13.456' },
  { nom: 'Site B', code: 'B002', long: '22.345', lat: '-21.789' },
  { nom: 'Site C', code: 'C003', long: '30.567', lat: '-19.345' },
  { nom: 'Site D', code: 'D004', long: '12.345', lat: '-22.678' },
  { nom: 'Site E', code: 'E005', long: '25.678', lat: '-18.901' },
  { nom: 'Site F', code: 'F006', long: '18.901', lat: '-14.345' },
  { nom: 'Site G', code: 'G007', long: '32.456', lat: '-15.678' },
  { nom: 'Site H', code: 'H008', long: '28.345', lat: '-20.567' },
  { nom: 'Site I', code: 'I009', long: '24.567', lat: '-17.890' },
  { nom: 'Site J', code: 'J010', long: '21.789', lat: '-12.345' },
  { nom: 'Site K', code: 'K011', long: '17.234', lat: '-19.678' },
  { nom: 'Site L', code: 'L012', long: '29.345', lat: '-21.890' },
  { nom: 'Site M', code: 'M013', long: '26.456', lat: '-16.789' },
  { nom: 'Site N', code: 'N014', long: '14.567', lat: '-20.123' },
  { nom: 'Site O', code: 'O015', long: '23.678', lat: '-13.456' },
  { nom: 'Site P', code: 'P016', long: '19.789', lat: '-17.678' },
  { nom: 'Site Q', code: 'Q017', long: '27.890', lat: '-18.345' },
  { nom: 'Site R', code: 'R018', long: '31.901', lat: '-15.789' },
  { nom: 'Site S', code: 'S019', long: '20.123', lat: '-19.234' },
  { nom: 'Site T', code: 'T020', long: '16.345', lat: '-14.567' },
];

const SiteListPage = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredSites, setFilteredSites] = useState(initialSites);
  const [open, setOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [siteData, setSiteData] = useState({ nom: '', code: '', long: '', lat: '' });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = (event) => {
    const search = event.target.value.toLowerCase();
    setSearchTerm(search);
    setFilteredSites(
      initialSites.filter(site =>
        site.nom.toLowerCase().includes(search) ||
        site.code.toLowerCase().includes(search) ||
        site.long.toLowerCase().includes(search) ||
        site.lat.toLowerCase().includes(search)
      )
    );
  };

  const handleClickOpen = () => {
    setEditMode(false);
    setSiteData({ nom: '', code: '', long: '', lat: '' });
    setOpen(true);
  };

  const handleClickEdit = (index) => {
    setEditMode(true);
    setCurrentIndex(index);
    setSiteData(filteredSites[index]);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setSiteData(prevState => ({ ...prevState, [name]: value }));
  };

  const handleSave = () => {
    if (siteData.nom.trim() && siteData.code.trim() && siteData.long.trim() && siteData.lat.trim()) {
      if (editMode) {
        // Mise à jour du site existant
        const updatedSites = [...filteredSites];
        updatedSites[currentIndex] = siteData;
        setFilteredSites(updatedSites);
      } else {
        // Ajout d'un nouveau site
        setFilteredSites([...filteredSites, siteData]);
      }
      setSiteData({ nom: '', code: '', long: '', lat: '' });
      setOpen(false);
    } else {
      alert('Veuillez remplir tous les champs.');
    }
  };

  const handleDelete = (index) => {
    const updatedSites = filteredSites.filter((_, i) => i !== index);
    setFilteredSites(updatedSites);
  };

  return (
    <Container>
      <Typography variant="h6" gutterBottom>
        Liste des Sites
      </Typography>
      <Button
        variant="contained"
        color="primary"
        startIcon={<AddIcon />}
        onClick={handleClickOpen}
        style={{ marginBottom: '16px' }}
      >
        Ajouter
      </Button>
      <TextField
        variant="outlined"
        margin="normal"
        fullWidth
        size='small'
        label="Rechercher"
        value={searchTerm}
        onChange={handleSearch}
      />
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow style={{ fontWeight: 'bold', textTransform: 'uppercase' }}>
              <TableCell>Nom</TableCell>
              <TableCell>Code</TableCell>
              <TableCell>Longitude</TableCell>
              <TableCell>Latitude</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredSites
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((site, index) => (
                <TableRow key={index}>
                  <TableCell>{site.nom}</TableCell>
                  <TableCell>{site.code}</TableCell>
                  <TableCell>{site.long}</TableCell>
                  <TableCell>{site.lat}</TableCell>
                  <TableCell>
                    <IconButton color="primary" onClick={() => handleClickEdit(index)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton color="secondary" onClick={() => handleDelete(index)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={filteredSites.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{editMode ? 'Modifier le Site' : 'Ajouter un Nouveau Site'}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                autoFocus
                margin="dense"
                label="Nom"
                type="text"
                fullWidth
                variant="outlined"
                name="nom"
                value={siteData.nom}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                margin="dense"
                label="Code"
                type="text"
                fullWidth
                variant="outlined"
                name="code"
                value={siteData.code}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                margin="dense"
                label="Longitude"
                type="text"
                fullWidth
                variant="outlined"
                name="long"
                value={siteData.long}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                margin="dense"
                label="Latitude"
                type="text"
                fullWidth
                variant="outlined"
                name="lat"
                value={siteData.lat}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Annuler
          </Button>
          <Button onClick={handleSave} color="primary">
            {editMode ? 'Modifier' : 'Ajouter'}
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default SiteListPage;
