import * as React from 'react';
import EmailIcon from '@mui/icons-material/Email';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
//import Logout from '@mui/icons-material/ExitToApp';
import Logout from '@mui/icons-material/Logout';
import { useNavigate } from 'react-router-dom';
import { IconButton, Avatar, Divider, ListItemIcon } from '@mui/material';
import { makeStyles } from '@mui/styles';
import secureLocalStorage from 'react-secure-storage';
import { authSelector } from '../redux/selectors/globalSelector';
import { useDispatch, useSelector } from 'react-redux';
import { signOut } from '../redux/actions/authActions';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        '& > *': {
            margin: theme.spacing(0),
        },
    },
    small: {
        width: theme.spacing(4.3),
        height: theme.spacing(4.3),
    },
    smallitem: {
        width: theme.spacing(3),
        height: theme.spacing(3),
        marginRight: theme.spacing(2),
    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
    userpromenu: {
        marginTop: theme.spacing(0)
    }
}));

export default function AccountMenu() {

    const auth = useSelector(authSelector);
    const dispatch = useDispatch();

    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    let navigate = useNavigate();

    const handleClick = (event) => {

        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        secureLocalStorage.setItem('item1', null);
        dispatch(signOut());
    };



    return (
        <React.Fragment className={classes.root}>
            <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>

                <Tooltip title="Profil Utilisateur">
                    <IconButton
                        onClick={handleClick}
                        size="small"
                        sx={{ ml: 2 }}
                        aria-controls={open ? 'account-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                    >
                        <Avatar className={classes.small}>{auth?.user?.name.toUpperCase().charAt(0)}</Avatar>
                    </IconButton>
                </Tooltip>
            </Box>
            <Menu
                className={classes.userpromenu}
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 2,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        paddingTop: 3,
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem>
                    <AccountCircleIcon className={classes.smallitem} />{auth?.user?.name??"Alexis ABDALA"}
                </MenuItem>
                <MenuItem>
                    <EmailIcon className={classes.smallitem} />{auth?.user?.email??"admin@artf.com"}
                </MenuItem>
                <Divider />
                <MenuItem>
                    <ListItemIcon>
                        <Settings />
                    </ListItemIcon>
                    Paramètres
                </MenuItem>
                <MenuItem onClick={handleLogout}>
                    <ListItemIcon>
                        <Logout />
                    </ListItemIcon>
                    Déconnexion
                </MenuItem>
            </Menu>
        </React.Fragment >
    );
}
