export const initialData = [
    { operateur: 'Opérateur 1', code: 'CODE001', date: '2024-01-01', pays: 'France', direction: 'entrant', agence: 'Agence A', montant: '1000' },
    { operateur: 'Opérateur 2', code: 'CODE002', date: '2024-01-02', pays: 'Allemagne', direction: 'sortant', agence: 'Agence B', montant: '2000' },
    { operateur: 'Opérateur 3', code: 'CODE003', date: '2024-01-03', pays: 'Espagne', direction: 'entrant', agence: 'Agence C', montant: '1500' },
    { operateur: 'Opérateur 4', code: 'CODE004', date: '2024-01-04', pays: 'Italie', direction: 'sortant', agence: 'Agence D', montant: '2500' },
    { operateur: 'Opérateur 5', code: 'CODE005', date: '2024-01-05', pays: 'Portugal', direction: 'entrant', agence: 'Agence E', montant: '3000' },
    { operateur: 'Opérateur 6', code: 'CODE006', date: '2024-01-06', pays: 'Pays-Bas', direction: 'sortant', agence: 'Agence F', montant: '1200' },
    { operateur: 'Opérateur 7', code: 'CODE007', date: '2024-01-07', pays: 'Belgique', direction: 'entrant', agence: 'Agence G', montant: '1800' },
    { operateur: 'Opérateur 8', code: 'CODE008', date: '2024-01-08', pays: 'Suisse', direction: 'sortant', agence: 'Agence H', montant: '2200' },
    { operateur: 'Opérateur 9', code: 'CODE009', date: '2024-01-09', pays: 'Autriche', direction: 'entrant', agence: 'Agence I', montant: '1100' },
    { operateur: 'Opérateur 10', code: 'CODE010', date: '2024-01-10', pays: 'Suède', direction: 'sortant', agence: 'Agence J', montant: '1400' },
    { operateur: 'Opérateur 11', code: 'CODE011', date: '2024-01-11', pays: 'Danemark', direction: 'entrant', agence: 'Agence K', montant: '1600' },
    { operateur: 'Opérateur 12', code: 'CODE012', date: '2024-01-12', pays: 'Norvège', direction: 'sortant', agence: 'Agence L', montant: '1900' },
    { operateur: 'Opérateur 13', code: 'CODE013', date: '2024-01-13', pays: 'Finlande', direction: 'entrant', agence: 'Agence M', montant: '2100' },
    { operateur: 'Opérateur 14', code: 'CODE014', date: '2024-01-14', pays: 'Irlande', direction: 'sortant', agence: 'Agence N', montant: '2300' },
    { operateur: 'Opérateur 15', code: 'CODE015', date: '2024-01-15', pays: 'Écosse', direction: 'entrant', agence: 'Agence O', montant: '2500' },
    { operateur: 'Opérateur 16', code: 'CODE016', date: '2024-01-16', pays: 'Wales', direction: 'sortant', agence: 'Agence P', montant: '2700' },
    { operateur: 'Opérateur 17', code: 'CODE017', date: '2024-01-17', pays: 'Islande', direction: 'entrant', agence: 'Agence Q', montant: '2900' },
    { operateur: 'Opérateur 18', code: 'CODE018', date: '2024-01-18', pays: 'Luxembourg', direction: 'sortant', agence: 'Agence R', montant: '3100' },
    { operateur: 'Opérateur 19', code: 'CODE019', date: '2024-01-19', pays: 'Malte', direction: 'entrant', agence: 'Agence S', montant: '3300' },
    { operateur: 'Opérateur 20', code: 'CODE020', date: '2024-01-20', pays: 'Chypre', direction: 'sortant', agence: 'Agence T', montant: '3500' },
    { operateur: 'Opérateur 21', code: 'CODE021', date: '2024-01-21', pays: 'Monaco', direction: 'entrant', agence: 'Agence U', montant: '3700' },
    { operateur: 'Opérateur 22', code: 'CODE022', date: '2024-01-22', pays: 'Andorre', direction: 'sortant', agence: 'Agence V', montant: '3900' },
    { operateur: 'Opérateur 23', code: 'CODE023', date: '2024-01-23', pays: 'San Marin', direction: 'entrant', agence: 'Agence W', montant: '4100' },
    { operateur: 'Opérateur 24', code: 'CODE024', date: '2024-01-24', pays: 'Vatican', direction: 'sortant', agence: 'Agence X', montant: '4300' },
    { operateur: 'Opérateur 25', code: 'CODE025', date: '2024-01-25', pays: 'Liechtenstein', direction: 'entrant', agence: 'Agence Y', montant: '4500' },
    { operateur: 'Opérateur 26', code: 'CODE026', date: '2024-01-26', pays: 'Gibraltar', direction: 'sortant', agence: 'Agence Z', montant: '4700' },
    { operateur: 'Opérateur 27', code: 'CODE027', date: '2024-01-27', pays: 'République tchèque', direction: 'entrant', agence: 'Agence AA', montant: '4900' },
    { operateur: 'Opérateur 28', code: 'CODE028', date: '2024-01-28', pays: 'Slovaquie', direction: 'sortant', agence: 'Agence BB', montant: '5100' },
    { operateur: 'Opérateur 29', code: 'CODE029', date: '2024-01-29', pays: 'Hongrie', direction: 'entrant', agence: 'Agence CC', montant: '5300' },
    { operateur: 'Opérateur 30', code: 'CODE030', date: '2024-01-30', pays: 'Pologne', direction: 'sortant', agence: 'Agence DD', montant: '5500' }
  ];
  