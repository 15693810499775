import * as React from 'react';
import ListeRecette from './list/ListRecette';

 const Recette = ()=>{

  return (
    <ListeRecette/>
  );
}

export default Recette;
