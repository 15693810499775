import React from 'react';
import { Box, Grid, Card, Typography } from '@mui/material';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ReceiptIcon from '@mui/icons-material/Receipt';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import HandshakeIcon from '@mui/icons-material/Handshake';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import PublicIcon from '@mui/icons-material/Public';

const BoxStat = () => {
  // Exemple de valeurs pour les recettes totales
  const totalRecettes = 1234567;
  const partPrestataire = totalRecettes * 0.35; // 35% du total pour le prestataire
  const partGouv = totalRecettes - partPrestataire; // 65% du total pour le gouvernement

  const formatNumber = (number) => {
    return number.toLocaleString('fr-FR', { minimumFractionDigits: 0 }).replace(/\s/g, '.');
  };

  return (
    <Box sx={{ marginBottom: 3 }}>
      <Grid container spacing={3}>
        {/* Boîtes supérieures */}
        <Grid item xs={12} sm={4}>
          <Card sx={{ padding: 3, display: 'flex', alignItems: 'center' }}>
            <Box sx={{
              backgroundColor: '#FF5722', // Orange background for money
              borderRadius: '50%',
              padding: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginRight: 2
            }}>
              <AttachMoneyIcon fontSize="large" sx={{ color: 'white' }} />
            </Box>
            <Box>
              <Typography variant="h7">Total Recettes</Typography>
              <Typography variant="h5">{formatNumber(totalRecettes)}</Typography>
            </Box>
          </Card>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Card sx={{ padding: 3, display: 'flex', alignItems: 'center' }}>
            <Box sx={{
              backgroundColor: '#3F51B5', // Blue background for receipt
              borderRadius: '50%',
              padding: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginRight: 2
            }}>
              <ReceiptIcon fontSize="large" sx={{ color: 'white' }} />
            </Box>
            <Box>
              <Typography variant="h7">Nbre Quittances</Typography>
              <Typography variant="h5">56.789</Typography>
            </Box>
          </Card>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Card sx={{ padding: 3, display: 'flex', alignItems: 'center' }}>
            <Box sx={{
              backgroundColor: '#4CAF50', // Green background for location
              borderRadius: '50%',
              padding: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginRight: 2
            }}>
              <LocationOnIcon fontSize="large" sx={{ color: 'white' }} />
            </Box>
            <Box>
              <Typography variant="h7">Nbre Sites</Typography>
              <Typography variant="h5">120</Typography>
            </Box>
          </Card>
        </Grid>
      </Grid>

      <Grid container spacing={3} sx={{ marginTop: 1 }}>
        {/* Boîtes inférieures */}
        <Grid item xs={12} sm={4}>
          <Card sx={{ padding: 3, display: 'flex', alignItems: 'center' }}>
            <Box sx={{
              backgroundColor: '#FFEB3B', // Yellow background for handshake
              borderRadius: '50%',
              padding: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginRight: 2
            }}>
              <HandshakeIcon fontSize="large" sx={{ color: 'black' }} />
            </Box>
            <Box>
              <Typography variant="h7">Taux de Recvrmnt</Typography>
              <Typography variant="h5">85,5%</Typography>
            </Box>
          </Card>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Card sx={{ padding: 3, display: 'flex', alignItems: 'center' }}>
            <Box sx={{
              backgroundColor: '#9C27B0', // Purple background for POS
              borderRadius: '50%',
              padding: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginRight: 2
            }}>
              <PointOfSaleIcon fontSize="large" sx={{ color: 'white' }} />
            </Box>
            <Box>
              <Typography variant="h7">Part Prestataire (35%)</Typography>
              <Typography variant="h5">{formatNumber(partPrestataire)}</Typography>
            </Box>
          </Card>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Card sx={{ padding: 3, display: 'flex', alignItems: 'center' }}>
            <Box sx={{
              backgroundColor: '#00BCD4', // Cyan background for globe
              borderRadius: '50%',
              padding: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginRight: 2
            }}>
              <PublicIcon fontSize="large" sx={{ color: 'white' }} />
            </Box>
            <Box>
              <Typography variant="h7">Part Gouv. (65%)</Typography>
              <Typography variant="h5">{formatNumber(partGouv)}</Typography>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default BoxStat;
