import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes  } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
//import Dashboard from './dashboard/Dashboard';
import Dashboard from './pages/taxes/Dashboard';
import Recette from './pages/taxes/Recette';
import Sector from './pages/taxes/Sector';
import Site from './pages/taxes/Site';
import Prestataire from './pages/taxes/Prestataire';
import Statistic from './pages/taxes/Statistic';
import DashboardIndex from './pages/taxes/DashboardIndex';
import Transaction from './pages/taxes/Transactions';
import Payment from './pages/taxes/list/ListSample';
import RoleManagement from './pages/taxes/list/RoleManagementUI';
import UserManagement from './pages/taxes/list/UserManagement';
import Company from './pages/taxes/list/ListCompany';

const App=()=>{
  return (
    <Provider store={store}>
     <ToastContainer />
        <Router>
          <Routes>
            <Route strict path="/" element={<DashboardIndex children={<Dashboard/>} title={"Tableau de Bord"}/>} />
            <Route strict path="/dash/index" element={<DashboardIndex children={<Dashboard/>} title={"Tableau de Bord"}/>} />
            <Route strict path="/dash/receipe" element={<DashboardIndex children={<Recette/>} title={"Recettes"}/>} />
            <Route strict path="/dash/sector" element={<DashboardIndex children={<Sector/>} title={"Secteurs"}/>} />
            <Route strict path="/dash/site" element={<DashboardIndex children={<Site/>} title={"Sites"}/>} />
            <Route strict path="/dash/prestataire" element={<DashboardIndex children={<Prestataire/>} title={"Prestataires"}/>} />
            <Route strict path="/dash/stat" element={<DashboardIndex children={<Statistic/>} title={"Statistiques"}/>} />
            <Route strict path="/dash/transactions" element={<DashboardIndex children={<Transaction/>} title={"Transactions"}/>} />
            <Route strict path="/dash/payments" element={<DashboardIndex children={<Payment/>} title={"Transactions"}/>} />
            <Route strict path="/dash/roles" element={<DashboardIndex children={<RoleManagement/>} title={"Détails Utilisateur"}/>} />
            <Route strict path="/dash/users" element={<DashboardIndex children={<UserManagement/>} title={"Utilisateurs"}/>} />
            <Route strict path="/dash/company" element={<DashboardIndex children={<Company/>} title={"Compagnies"}/>} />
            <Route path="*" element={<div>Not Found</div>} /> 
         </Routes>
        </Router>
    </Provider>
  );
}

export default App;
