import { SIGN_ERROR, SIGN_IN, SIGN_OUT, SIGN_UP } from "../variableActions";
import { userAPI } from "../../api/apiIndex";
import secureLocalStorage from "react-secure-storage";



export const signIn = (options, component) => {
    //console.log(options);
    return async dispatch => {
        try {

            component.setDisabledBtn(true); 

            const response = await userAPI.apiUserSignIn(options);
            if(response.ok){
                const {data} = await response.json();
                secureLocalStorage.setItem("item1", data);

                dispatch({
                    type: SIGN_IN,
                    payload: { ...data }
                });
            }else {
                if([400, 401, 404, 500].includes(response.status)){
                    const {message} = await response.json();
                  
                    dispatch({
                        type: SIGN_ERROR,
                        payload: message
                    })
                } else {
                    dispatch({
                        type: SIGN_ERROR,
                        payload: "Une erreur inattendue est survenue"
                    })
                }
            }

        component.setDisabledBtn(false); 

        } catch (error) {
            console.log(error);

            dispatch({
                type: SIGN_ERROR,
                payload: "Une erreur inattendue est survenue"
            })

            component.setDisabledBtn(false); 
        }
    }
}


export const signOut = () => ({
    type: SIGN_OUT,
    payload: {}
});
