import React, { useState } from 'react';
import {
  Container,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  TextField,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import SearchIcon from '@mui/icons-material/Search';
import RefreshIcon from '@mui/icons-material/Refresh';
import { initialData } from '../init/initTransac';

const DataListPage = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchOperateur, setSearchOperateur] = useState('');
  const [searchCode, setSearchCode] = useState('');
  const [searchDate, setSearchDate] = useState('');
  const [searchPays, setSearchPays] = useState('');
  const [searchDirection, setSearchDirection] = useState('');
  const [searchAgence, setSearchAgence] = useState('');
  const [searchMontant, setSearchMontant] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [open, setOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [newData, setNewData] = useState({
    operateur: '',
    code: '',
    date: '',
    pays: '',
    direction: '',
    agence: '',
    montant: '',
  });
  const [defaultData, setDefaultData] = React.useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = () => {
    const result = defaultData.filter(data =>
      data.operateur?.toLowerCase().includes(searchOperateur.toLowerCase()) &&
      data.code?.toLowerCase().includes(searchCode.toLowerCase()) &&
      data.date?.toLowerCase().includes(searchDate.toLowerCase()) &&
      data.pays?.toLowerCase().includes(searchPays.toLowerCase()) &&
      data.direction?.toLowerCase().includes(searchDirection.toLowerCase()) &&
      data.agence?.toLowerCase().includes(searchAgence.toLowerCase()) &&
      data.montant?.toLowerCase().includes(searchMontant.toLowerCase())
    );
    setFilteredData(result);
  };

  const handleClickOpen = () => {
    setEditMode(false);
    setOpen(true);
  };

  const handleClickEdit = (index) => {
    setEditMode(true);
    setCurrentIndex(index);
    setNewData(filteredData[index]);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setNewData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSave = () => {
    if (editMode) {
      // Update existing data
      const updatedData = [...filteredData];
      updatedData[currentIndex] = newData;
      setFilteredData(updatedData);
    } else {
      // Add new data
      setFilteredData([...filteredData, newData]);
    }
    setNewData({
      operateur: '',
      code: '',
      date: '',
      pays: '',
      direction: '',
      agence: '',
      montant: '',
    });
    setOpen(false);
  };

  const handleDelete = (index) => {
    const updatedData = filteredData.filter((_, i) => i !== index);
    setFilteredData(updatedData);
  };

  React.useEffect(()=>{
    (async()=>{
      try {
        const response = await fetch("https://artefnetwork.com/api/transaction");
      if(response.ok){
        const {data} = await response.json();
        let formatedData = data.map(item=>{
          return {
            operateur: 'WESTERN UNION',
            code: item.uuid.split("-").pop(),
            date: new Date(item.createdAt).toISOString().split("T")[0].split("-").reverse().join("/"),
            pays: 'Congo',
            direction: item.action,
            agence: JSON.parse(item.entreprise).name,
            montant: item.amount,
          }
        });

        setDefaultData(formatedData);
      }
      } catch (error) {
        
      }
    }) ();
  }, []);


  React.useEffect(()=>{
    setFilteredData(defaultData);
  }, [defaultData]);

  return (
    <Container>
      <Typography variant="h6" gutterBottom style={{fontSize: 16, textDecoration: 'underline'}}>
        Liste des Transactions
      </Typography>
      <Grid container spacing={2} marginBottom={2} marginTop={2}>

        <Grid item xs={12} sm={2}>
          <TextField
            variant="outlined"
            fullWidth
            size='small'
            label="Opérateur"
            value={searchOperateur}
            onChange={(e) => setSearchOperateur(e.target.value)}
            InputProps={{style: { fontSize: '0.80rem', padding: '1px !important', height: '35px',  display: 'flex',
                alignItems: 'center', borderRadius: '20px'}}}
            InputLabelProps={{
                sx: {
                  display: 'flex',
                  alignItems: 'center', // Centre le label verticalement
                  fontSize: '0.80rem', // Ajustez la taille de la police si nécessaire
                },
              }}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <TextField
            variant="outlined"
            fullWidth
            size='small'
            label="Code"
            value={searchCode}
            onChange={(e) => setSearchCode(e.target.value)}
            InputProps={{style: { fontSize: '0.80rem', padding: '1px !important', height: '35px',  display: 'flex',
                alignItems: 'center', borderRadius: '20px'}}}
            InputLabelProps={{
                sx: {
                  display: 'flex',
                  alignItems: 'center', // Centre le label verticalement
                  fontSize: '0.80rem', // Ajustez la taille de la police si nécessaire
                },
              }}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <TextField
            variant="outlined"
            fullWidth
            size='small'
            type="date"
            value={searchDate}
            onChange={(e) => setSearchDate(e.target.value)}
            InputProps={{style: { fontSize: '0.80rem', padding: '1px !important', height: '35px',  display: 'flex',
                alignItems: 'center', borderRadius: '20px'}}}
            InputLabelProps={{
                sx: {
                  display: 'flex',
                  alignItems: 'center', // Centre le label verticalement
                  fontSize: '0.80rem', // Ajustez la taille de la police si nécessaire
                },
              }}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <TextField
            variant="outlined"
            fullWidth
            size='small'
            label="Pays"
            value={searchPays}
            onChange={(e) => setSearchPays(e.target.value)}
            InputProps={{style: { fontSize: '0.80rem', padding: '1px !important', height: '35px',  display: 'flex',
                alignItems: 'center', borderRadius: '20px'}}}
            InputLabelProps={{
                sx: {
                  display: 'flex',
                  alignItems: 'center', // Centre le label verticalement
                  fontSize: '0.80rem', // Ajustez la taille de la police si nécessaire
                },
              }}
          />
        </Grid>
  
        <Grid item xs={12} sm={2}>
          <TextField
            variant="outlined"
            fullWidth
            size="small"
            label="Agence"
            value={searchAgence}
            InputProps={{style: { fontSize: '0.80rem', padding: '1px !important', height: '35px',  display: 'flex',
                alignItems: 'center', borderRadius: '20px'}}}
            InputLabelProps={{
                sx: {
                  display: 'flex',
                  alignItems: 'center', // Centre le label verticalement
                  fontSize: '0.80rem', // Ajustez la taille de la police si nécessaire
                },
              }}
            onChange={(e) => setSearchAgence(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={1}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSearch}
            style={{ height: '100%', fontSize: '10px', borderRadius: '20px', paddingInline:0 }}
          >
            <SearchIcon/>
          </Button>
        </Grid>

        <Grid item xs={12} sm={1}>
          <Button
            variant="contained"
            color="primary"
            onClick={()=>{
                let clearData={};
                Object.keys(newData).forEach(item=>{
                    clearData[item] = '';
                });
                
                setSearchOperateur('');
                setSearchCode('');
                setSearchDate('');
                setSearchPays('');
                setSearchAgence('');

                setNewData(clearData);
                setFilteredData(defaultData);
                //alert(JSON.stringify(clearData));
            }
            }
            style={{ height: '100%', fontSize: '10px', borderRadius: '20px' }}
          >
            <RefreshIcon/>
          </Button>
        </Grid>
      </Grid>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow style={{ fontWeight: 'bold', textTransform: 'uppercase', backgroundColor: '#DCDCDC' }}>
              <TableCell style={{paddingTop: '2px', paddingBottom: '2px'}}>Service</TableCell>
              <TableCell style={{paddingTop: '1px', paddingBottom: '1px'}}>Code</TableCell>
              <TableCell style={{paddingTop: '1px', paddingBottom: '1px'}}>Date</TableCell>
              <TableCell style={{paddingTop: '1px', paddingBottom: '1px'}}>Pays</TableCell>
              <TableCell style={{paddingTop: '1px', paddingBottom: '1px'}}>Direction</TableCell>
              <TableCell style={{paddingTop: '1px', paddingBottom: '1px'}}>Agence</TableCell>
              <TableCell style={{paddingTop: '1px', paddingBottom: '1px'}}>Montant</TableCell>
              <TableCell style={{paddingTop: '1px', paddingBottom: '1px'}}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((data, index) => (
                <TableRow style={{paddingTop: '0px', paddingBottom: '0px'}} key={index}>
                  <TableCell style={{paddingTop: '0px', paddingBottom: '0px'}}>{data.operateur}</TableCell>
                  <TableCell style={{paddingTop: '0px', paddingBottom: '0px'}}>{data.code}</TableCell>
                  <TableCell style={{paddingTop: '0px', paddingBottom: '0px'}}>{data.date}</TableCell>
                  <TableCell style={{paddingTop: '0px', paddingBottom: '0px'}}>{data.pays}</TableCell>
                  <TableCell style={{paddingTop: '0px', paddingBottom: '0px'}}>{data.direction}</TableCell>
                  <TableCell style={{paddingTop: '0px', paddingBottom: '0px'}}>{data.agence}</TableCell>
                  <TableCell style={{paddingTop: '0px', paddingBottom: '0px'}}>{data.montant}</TableCell>
                  <TableCell style={{paddingTop: '0px', paddingBottom: '0px'}}>
                    <IconButton onClick={() => handleClickEdit(index)}>
                      <VisibilityIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={filteredData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{editMode ? 'Modifier les données' : 'Ajouter des données'}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Opérateur"
                name="operateur"
                value={newData.operateur}
                onChange={handleChange}
                fullWidth
                margin="normal"
                size='small'
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Code"
                name="code"
                value={newData.code}
                onChange={handleChange}
                fullWidth
                margin="normal"
                size='small'
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Date"
                name="date"
                type="date"
                InputLabelProps={{ shrink: true }}
                value={newData.date}
                onChange={handleChange}
                fullWidth
                margin="normal"
                size='small'
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Pays"
                name="pays"
                value={newData.pays}
                onChange={handleChange}
                fullWidth
                margin="normal"
                size='small'
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Direction"
                name="direction"
                value={newData.direction}
                onChange={handleChange}
                fullWidth
                margin="normal"
                size='small'
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Agence"
                name="agence"
                value={newData.agence}
                onChange={handleChange}
                fullWidth
                margin="normal"
                size='small'
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Montant"
                name="montant"
                type="number"
                value={newData.montant}
                onChange={handleChange}
                fullWidth
                margin="normal"
                size='small'
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Annuler
          </Button>
          <Button onClick={handleSave} color="primary">
            {editMode ? 'Sauvegarder' : 'Ajouter'}
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default DataListPage;
