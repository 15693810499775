import React, { useState } from 'react';
import {
  Container,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { initialSectors } from '../init/initialSectors';

const SectorListPage = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredSectors, setFilteredSectors] = useState(initialSectors);
  const [open, setOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [sectorData, setSectorData] = useState({ nom: '', code: '' });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = (event) => {
    const search = event.target.value.toLowerCase();
    setSearchTerm(search);
    setFilteredSectors(
      initialSectors.filter(sector =>
        sector.nom.toLowerCase().includes(search) ||
        sector.code.toLowerCase().includes(search)
      )
    );
  };

  const handleClickOpen = () => {
    setEditMode(false);
    setSectorData({ nom: '', code: '' });
    setOpen(true);
  };

  const handleClickEdit = (index) => {
    setEditMode(true);
    setCurrentIndex(index);
    setSectorData(filteredSectors[index]);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setSectorData(prevState => ({ ...prevState, [name]: value }));
  };

  const handleSave = () => {
    if (sectorData.nom.trim() && sectorData.code.trim()) {
      if (editMode) {
        // Mise à jour du secteur existant
        const updatedSectors = [...filteredSectors];
        updatedSectors[currentIndex] = sectorData;
        setFilteredSectors(updatedSectors);
      } else {
        // Ajout d'un nouveau secteur
        setFilteredSectors([...filteredSectors, sectorData]);
      }
      setSectorData({ nom: '', code: '' });
      setOpen(false);
    } else {
      alert('Veuillez remplir tous les champs.');
    }
  };

  const handleDelete = (index) => {
    const updatedSectors = filteredSectors.filter((_, i) => i !== index);
    setFilteredSectors(updatedSectors);
  };

  return (
    <Container>
      <Typography variant="h6" gutterBottom>
        Liste des Secteurs
      </Typography>
      <Button
        variant="contained"
        color="primary"
        startIcon={<AddIcon />}
        onClick={handleClickOpen}
        style={{ marginBottom: '16px' }}
      >
        Ajouter
      </Button>
      <TextField
        variant="outlined"
        margin="normal"
        fullWidth
        size='small'
        label="Rechercher"
        value={searchTerm}
        onChange={handleSearch}
      />
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow style={{ fontWeight: 'bold', textTransform: 'uppercase' }}>
              <TableCell>Nom</TableCell>
              <TableCell>Code</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredSectors
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((sector, index) => (
                <TableRow key={index}>
                  <TableCell>{sector.nom}</TableCell>
                  <TableCell>{sector.code}</TableCell>
                  <TableCell>
                    <IconButton color="primary" onClick={() => handleClickEdit(index)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton color="secondary" onClick={() => handleDelete(index)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={filteredSectors.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{editMode ? 'Modifier le Secteur' : 'Ajouter un Nouveau Secteur'}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                autoFocus
                margin="dense"
                label="Nom"
                type="text"
                fullWidth
                variant="outlined"
                name="nom"
                value={sectorData.nom}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                margin="dense"
                label="Code"
                type="text"
                fullWidth
                variant="outlined"
                name="code"
                value={sectorData.code}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Annuler
          </Button>
          <Button onClick={handleSave} color="primary">
            {editMode ? 'Sauvegarder' : 'Ajouter'}
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default SectorListPage;
