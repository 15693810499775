import * as React from 'react';
import PrestataireList from './list/PrestataireList';

 const Prestataire = ()=>{

  return (
    <PrestataireList/>
  );
}

export default Prestataire;