import React, { useState, useEffect } from 'react';
import { Container, Typography, Grid, Card, CardContent, Box, TextField, InputAdornment, IconButton, Button, Select, MenuItem, Checkbox, ListItemText } from '@mui/material';
import { Line, Bar, Doughnut, Radar, PolarArea } from 'react-chartjs-2';
import { DateRangePicker } from 'react-date-range';
import { addMonths, format, subDays } from 'date-fns';
import { fr } from 'date-fns/locale';
import 'react-date-range/dist/styles.css'; // Import styles
import 'react-date-range/dist/theme/default.css'; // Import theme styles
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

// Chart.js imports
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  RadarController,
  LineElement,
  PointElement,
  Filler,
  RadialLinearScale
} from 'chart.js';
import BoxStat from './BoxStat';
import MapComponent from './MapComponent';

// Register Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  RadarController,
  LineElement,
  PointElement,
  Filler,
  RadialLinearScale
);

const StatisticsPage = () => {
  const [dateRange, setDateRange] = useState({
    startDate: new Date(),
    endDate: addMonths(new Date(), 1),
    key: 'selection'
  });
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [selectedCriteria, setSelectedCriteria] = useState([]);
  const [chartData, setChartData] = useState({
    recettes: { labels: [], data: [] },
    sites: { labels: [], data: [] },
    prestataires: { labels: [], data: [] },
    secteurs: { labels: [], data: [] },
    radar: { labels: [], data: [] },
    doughnut: { labels: [], data: [] },
    polarArea: { labels: [], data: [] }
  });

  useEffect(() => {
    const { labels, dataRecettes } = generateData(dateRange.startDate, dateRange.endDate);
    setChartData({
      recettes: {
        labels,
        data: dataRecettes
      },
      sites: {
        labels,
        data: dataRecettes // Exemple de données
      },
      prestataires: {
        labels,
        data: dataRecettes // Exemple de données
      },
      secteurs: {
        labels,
        data: dataRecettes // Exemple de données
      },
      radar: {
        labels,
        data: dataRecettes // Exemple de données
      },
      doughnut: {
        labels,
        data: dataRecettes // Exemple de données
      },
      polarArea: {
        labels,
        data: dataRecettes // Exemple de données
      }
    });
  }, [dateRange]);

  const handleDateRangeChange = (item) => {
    setDateRange(item.selection);
    setShowDatePicker(false);
  };

  const handlePresetChange = (preset) => {
    const today = new Date();
    switch (preset) {
      case 'yesterday':
        setDateRange({
          startDate: subDays(today, 1),
          endDate: subDays(today, 1),
          key: 'selection'
        });
        break;
      case 'today':
        setDateRange({
          startDate: today,
          endDate: today,
          key: 'selection'
        });
        break;
      case 'last7':
        setDateRange({
          startDate: subDays(today, 7),
          endDate: today,
          key: 'selection'
        });
        break;
      case 'last30':
        setDateRange({
          startDate: subDays(today, 30),
          endDate: today,
          key: 'selection'
        });
        break;
      default:
        break;
    }
    setShowDatePicker(false);
  };

  const handleCriteriaChange = (event) => {
    setSelectedCriteria(event.target.value);
  };

  const generateData = (startDate, endDate) => {
    const labels = [];
    const data = [];
    let currentDate = new Date(startDate);
    while (currentDate <= endDate) {
      labels.push(format(currentDate, 'MMM yyyy', { locale: fr }));
      data.push(Math.floor(Math.random() * 1000)); // Génération de données aléatoires pour l'exemple
      currentDate = addMonths(currentDate, 1);
    }
    console.log('Labels:', labels); // Affichage des labels
    console.log('Data:', data); // Affichage des données
    return { labels, dataRecettes: data };
  };

  const dataRecettesChart = {
    labels: chartData.recettes.labels,
    datasets: [
      {
        label: 'Recettes Mensuelles',
        data: chartData.recettes.data,
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
    ],
  };

  const dataSitesChart = {
    labels: chartData.sites.labels,
    datasets: [
      {
        label: 'Visiteurs par Site',
        data: chartData.sites.data,
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 1,
      },
    ],
  };

  const dataPrestatairesChart = {
    labels: chartData.prestataires.labels,
    datasets: [
      {
        label: 'Transactions par Prestataire',
        data: chartData.prestataires.data,
        backgroundColor: 'rgba(153, 102, 255, 0.2)',
        borderColor: 'rgba(153, 102, 255, 1)',
        borderWidth: 1,
      },
    ],
  };

  const dataSecteursChart = {
    labels: chartData.secteurs.labels,
    datasets: [
      {
        label: 'Revenu par Secteur',
        data: chartData.secteurs.data,
        backgroundColor: 'rgba(255, 159, 64, 0.2)',
        borderColor: 'rgba(255, 159, 64, 1)',
        borderWidth: 1,
      },
    ],
  };

  const dataRadar = {
    labels: chartData.radar.labels,
    datasets: [
      {
        label: 'Performance',
        data: chartData.radar.data,
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 1,
      },
    ],
  };

  const dataDoughnut = {
    labels: chartData.doughnut.labels,
    datasets: [
      {
        label: 'Répartition',
        data: chartData.doughnut.data,
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
    ],
  };

  const dataPolarArea = {
    labels: chartData.polarArea.labels,
    datasets: [
      {
        label: 'Répartition des Options',
        data: chartData.polarArea.data,
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 1,
      },
    ],
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Page de Statistiques
      </Typography>
      <Box mb={3} display="flex" justifyContent="flex-end" alignItems="center" position="relative">
        <TextField
          variant="outlined"
          label="Période"
          size='small'
          value={`${format(dateRange.startDate, 'dd MMM yyyy', { locale: fr })} - ${format(dateRange.endDate, 'dd MMM yyyy', { locale: fr })}`}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowDatePicker(!showDatePicker)}>
                  <CalendarTodayIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
          onClick={() => setShowDatePicker(!showDatePicker)}
        />
        <Select
          multiple
          value={selectedCriteria}
          onChange={handleCriteriaChange}
          renderValue={(selected) => selected.join(', ')}
          size='small'
          sx={{ ml: 2, width: 300 }}
        >
          {['Par Prestataire', 'Par Site', 'Par Taxe', 'Par Secteur'].map((option) => (
            <MenuItem key={option} value={option}>
              <Checkbox checked={selectedCriteria.indexOf(option) > -1} />
              <ListItemText primary={option} />
            </MenuItem>
          ))}
        </Select>
        {showDatePicker && (
          <Box
            sx={{
              position: 'absolute',
              top: '100%',
              right: 0,
              zIndex: 1000,
            }}
          >
            <DateRangePicker
              ranges={[dateRange]}
              onChange={handleDateRangeChange}
              locale={fr}
              months={2}
              direction="horizontal"
            />
          </Box>
        )}
      </Box>

      <BoxStat/>  

      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Card>
            <CardContent>
              <Typography variant="h6">Recettes Mensuelles</Typography>
              <Line data={dataRecettesChart} />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Card>
            <CardContent>
              <Typography variant="h6">Visiteurs par Site</Typography>
              <Bar data={dataSitesChart} />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Card>
            <CardContent>
              <Typography variant="h6">Transactions par Prestataire</Typography>
              <Bar data={dataPrestatairesChart} />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Card>
            <CardContent>
              <Typography variant="h6">Revenu par Secteur</Typography>
              <Bar data={dataSecteursChart} />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Card>
            <CardContent>
              <Typography variant="h6">Performance Radar</Typography>
              <Radar data={dataRadar} />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Card>
            <CardContent>
              <Typography variant="h6">Répartition Doughnut</Typography>
              <Doughnut data={dataDoughnut} />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Card>
            <CardContent>
              <Typography variant="h6">Répartition des Options</Typography>
              <PolarArea data={dataPolarArea} />
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <MapComponent/>
      
    </Container>
  );
};

export default StatisticsPage;
