import * as React from 'react';
import TransactionIndex from './list/ListTransac';

 const Transaction = ()=>{

  return (
    <TransactionIndex/>
  );
}

export default Transaction;