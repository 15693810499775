import { SIGN_ERROR, SIGN_OUT, SIGN_UP, SIGN_IN } from "../variableActions";
import { toast } from 'react-toastify';
import  secureLocalStorage  from  "react-secure-storage";



const intialState = {
    token: secureLocalStorage.getItem("item1") !== null && secureLocalStorage.getItem("item1") !== '' ? secureLocalStorage.getItem("item1").token : null,
    user: secureLocalStorage.getItem("item1") !== null && secureLocalStorage.getItem("item1") !== '' ?  secureLocalStorage.getItem("item1").user : null
};


export const authReducer = (state = intialState, action) => {

    switch (action.type) {

        case SIGN_IN:
            toast.success('Vous êtes connecté.e.s')
            const { token, user } = action.payload
            //const { user } = action.payload.data
            return { token, user };

        case SIGN_OUT:
            toast.info('Vous avez quitté le système. Aurevoir!')
            return action.payload;

        case SIGN_ERROR:
            toast.error(action?.payload)
            return action?.payload;

        default:
            return { ...state };

    }

}