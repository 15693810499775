import React, { useState } from 'react';
import {
  Container,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const initialPrestataires = [
  { nom: 'Prestataire A', code: 'PA001', rccm: 'RCCM001', adresse: 'Adresse A', telephone: '0123456789', email: 'contact@prestatairea.com', responsable: 'Responsable A' },
  { nom: 'Prestataire B', code: 'PB002', rccm: 'RCCM002', adresse: 'Adresse B', telephone: '0987654321', email: 'contact@prestataireb.com', responsable: 'Responsable B' },
  { nom: 'Prestataire C', code: 'PC003', rccm: 'RCCM003', adresse: 'Adresse C', telephone: '0234567890', email: 'contact@prestatairec.com', responsable: 'Responsable C' },
  { nom: 'Prestataire D', code: 'PD004', rccm: 'RCCM004', adresse: 'Adresse D', telephone: '0345678901', email: 'contact@prestataired.com', responsable: 'Responsable D' },
  { nom: 'Prestataire E', code: 'PE005', rccm: 'RCCM005', adresse: 'Adresse E', telephone: '0456789012', email: 'contact@prestatairee.com', responsable: 'Responsable E' },
  { nom: 'Prestataire F', code: 'PF006', rccm: 'RCCM006', adresse: 'Adresse F', telephone: '0567890123', email: 'contact@prestatairef.com', responsable: 'Responsable F' },
  { nom: 'Prestataire G', code: 'PG007', rccm: 'RCCM007', adresse: 'Adresse G', telephone: '0678901234', email: 'contact@prestataireg.com', responsable: 'Responsable G' },
  { nom: 'Prestataire H', code: 'PH008', rccm: 'RCCM008', adresse: 'Adresse H', telephone: '0789012345', email: 'contact@prestataireh.com', responsable: 'Responsable H' },
  { nom: 'Prestataire I', code: 'PI009', rccm: 'RCCM009', adresse: 'Adresse I', telephone: '0890123456', email: 'contact@prestatairei.com', responsable: 'Responsable I' },
  { nom: 'Prestataire J', code: 'PJ010', rccm: 'RCCM010', adresse: 'Adresse J', telephone: '0901234567', email: 'contact@prestatairej.com', responsable: 'Responsable J' },
  { nom: 'Prestataire K', code: 'PK011', rccm: 'RCCM011', adresse: 'Adresse K', telephone: '1012345678', email: 'contact@prestatairek.com', responsable: 'Responsable K' },
  { nom: 'Prestataire L', code: 'PL012', rccm: 'RCCM012', adresse: 'Adresse L', telephone: '1123456789', email: 'contact@prestatairel.com', responsable: 'Responsable L' },
  { nom: 'Prestataire M', code: 'PM013', rccm: 'RCCM013', adresse: 'Adresse M', telephone: '1234567890', email: 'contact@prestatairem.com', responsable: 'Responsable M' },
  { nom: 'Prestataire N', code: 'PN014', rccm: 'RCCM014', adresse: 'Adresse N', telephone: '1345678901', email: 'contact@prestatairen.com', responsable: 'Responsable N' },
  { nom: 'Prestataire O', code: 'PO015', rccm: 'RCCM015', adresse: 'Adresse O', telephone: '1456789012', email: 'contact@prestataireo.com', responsable: 'Responsable O' },
  { nom: 'Prestataire P', code: 'PP016', rccm: 'RCCM016', adresse: 'Adresse P', telephone: '1567890123', email: 'contact@prestatairep.com', responsable: 'Responsable P' },
  { nom: 'Prestataire Q', code: 'PQ017', rccm: 'RCCM017', adresse: 'Adresse Q', telephone: '1678901234', email: 'contact@prestataireq.com', responsable: 'Responsable Q' },
  { nom: 'Prestataire R', code: 'PR018', rccm: 'RCCM018', adresse: 'Adresse R', telephone: '1789012345', email: 'contact@prestatairer.com', responsable: 'Responsable R' },
  { nom: 'Prestataire S', code: 'PS019', rccm: 'RCCM019', adresse: 'Adresse S', telephone: '1890123456', email: 'contact@prestataires.com', responsable: 'Responsable S' },
  { nom: 'Prestataire T', code: 'PT020', rccm: 'RCCM020', adresse: 'Adresse T', telephone: '1901234567', email: 'contact@prestatairet.com', responsable: 'Responsable T' },
];

const PrestataireListPage = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredPrestataires, setFilteredPrestataires] = useState(initialPrestataires);
  const [open, setOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [prestataireData, setPrestataireData] = useState({
    nom: '',
    code: '',
    rccm: '',
    adresse: '',
    telephone: '',
    email: '',
    responsable: ''
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = (event) => {
    const search = event.target.value.toLowerCase();
    setSearchTerm(search);
    setFilteredPrestataires(
      initialPrestataires.filter(prestataire =>
        prestataire.nom.toLowerCase().includes(search) ||
        prestataire.code.toLowerCase().includes(search) ||
        prestataire.rccm.toLowerCase().includes(search) ||
        prestataire.adresse.toLowerCase().includes(search) ||
        prestataire.telephone.toLowerCase().includes(search) ||
        prestataire.email.toLowerCase().includes(search) ||
        prestataire.responsable.toLowerCase().includes(search)
      )
    );
  };

  const handleClickOpen = () => {
    setEditMode(false);
    setPrestataireData({
      nom: '',
      code: '',
      rccm: '',
      adresse: '',
      telephone: '',
      email: '',
      responsable: ''
    });
    setOpen(true);
  };

  const handleClickEdit = (index) => {
    setEditMode(true);
    setCurrentIndex(index);
    setPrestataireData(filteredPrestataires[index]);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setPrestataireData(prevState => ({ ...prevState, [name]: value }));
  };

  const handleSave = () => {
    if (
      prestataireData.nom.trim() &&
      prestataireData.code.trim() &&
      prestataireData.rccm.trim() &&
      prestataireData.adresse.trim() &&
      prestataireData.telephone.trim() &&
      prestataireData.email.trim() &&
      prestataireData.responsable.trim()
    ) {
      if (editMode) {
        // Mise à jour du prestataire existant
        const updatedPrestataires = [...filteredPrestataires];
        updatedPrestataires[currentIndex] = prestataireData;
        setFilteredPrestataires(updatedPrestataires);
      } else {
        // Ajout d'un nouveau prestataire
        setFilteredPrestataires([...filteredPrestataires, prestataireData]);
      }
      setPrestataireData({
        nom: '',
        code: '',
        rccm: '',
        adresse: '',
        telephone: '',
        email: '',
        responsable: ''
      });
      setOpen(false);
    } else {
      alert('Tous les champs doivent être remplis');
    }
  };

  const handleDelete = (index) => {
    const updatedPrestataires = filteredPrestataires.filter((_, i) => i !== index);
    setFilteredPrestataires(updatedPrestataires);
  };

  return (
    <Container>
      <Typography variant="h6" gutterBottom>
        Liste des Prestataires
      </Typography>
      <Button
        variant="contained"
        color="primary"
        startIcon={<AddIcon />}
        onClick={handleClickOpen}
        style={{ marginBottom: '16px' }}
      >
        Ajouter
      </Button>
      <TextField
        variant="outlined"
        margin="normal"
        fullWidth
        size="small"
        label="Rechercher"
        value={searchTerm}
        onChange={handleSearch}
      />
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow style={{ fontWeight: 'bold', textTransform: 'uppercase' }}>
              <TableCell>Nom</TableCell>
              <TableCell>Code</TableCell>
              <TableCell>RCCM</TableCell>
              <TableCell>Adresse</TableCell>
              <TableCell>Téléphone</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Responsable</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredPrestataires
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((prestataire, index) => (
                <TableRow key={index}>
                  <TableCell>{prestataire.nom}</TableCell>
                  <TableCell>{prestataire.code}</TableCell>
                  <TableCell>{prestataire.rccm}</TableCell>
                  <TableCell>{prestataire.adresse}</TableCell>
                  <TableCell>{prestataire.telephone}</TableCell>
                  <TableCell>{prestataire.email}</TableCell>
                  <TableCell>{prestataire.responsable}</TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleClickEdit(index)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton onClick={() => handleDelete(index)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={filteredPrestataires.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{editMode ? 'Modifier Prestataire' : 'Ajouter Prestataire'}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                margin="dense"
                label="Nom"
                type="text"
                fullWidth
                variant="outlined"
                name="nom"
                value={prestataireData.nom}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                margin="dense"
                label="Code"
                type="text"
                fullWidth
                variant="outlined"
                name="code"
                value={prestataireData.code}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                margin="dense"
                label="RCCM"
                type="text"
                fullWidth
                variant="outlined"
                name="rccm"
                value={prestataireData.rccm}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                margin="dense"
                label="Adresse"
                type="text"
                fullWidth
                variant="outlined"
                name="adresse"
                value={prestataireData.adresse}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                margin="dense"
                label="Téléphone"
                type="text"
                fullWidth
                variant="outlined"
                name="telephone"
                value={prestataireData.telephone}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                margin="dense"
                label="Email"
                type="email"
                fullWidth
                variant="outlined"
                name="email"
                value={prestataireData.email}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                margin="dense"
                label="Responsable"
                type="text"
                fullWidth
                variant="outlined"
                name="responsable"
                value={prestataireData.responsable}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Annuler
          </Button>
          <Button onClick={handleSave} color="primary">
            {editMode ? 'Modifier' : 'Ajouter'}
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default PrestataireListPage;
