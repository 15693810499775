import * as React from 'react';
import { Link } from 'react-router-dom';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Collapse from '@mui/material/Collapse';
import DashboardIcon from '@mui/icons-material/Dashboard';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import BusinessIcon from '@mui/icons-material/Business';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PeopleIcon from '@mui/icons-material/People';
import SettingsIcon from '@mui/icons-material/Settings';
import BarChartIcon from '@mui/icons-material/BarChart';
import WarningIcon from '@mui/icons-material/Warning';
import ReportIcon from '@mui/icons-material/Report';
import LockIcon from '@mui/icons-material/Lock';
import GavelIcon from '@mui/icons-material/Gavel';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRight from '@mui/icons-material/ChevronRight';
import HomeIcon from '@mui/icons-material/Home';

const menuItems = [
  { text: 'Tableau', icon: <HomeIcon />, path: '/dash/index' },
  {
    text: 'Opérateurs',
    icon: <BusinessIcon />,
    subMenu: [
      { text: 'Visualiser', path: '/dash/company' },
      { text: 'Banques', path: '/dash/operateurs/banques' },
      { text: 'Télécoms', path: '/dash/operateurs/telecoms' },
      { text: 'Agences de Transfert', path: '/dash/operateurs/agences' },
      { text: 'Microfinances', path: '/dash/operateurs/microfin' },
      { text: 'Assurances', path: '/dash/operateurs/assurances' },
      { text: 'Agence Forex', path: '/dash/operateurs/forex' },
    ]
  },
  {
    text: 'Transactions',
    icon: <BarChartIcon />,
    subMenu: [
      { text: 'Visualiser', path: '/dash/transactions' },
      { text: 'Temps Réel', path: '/dash/transactions/real-time' }
    ]
  },
  {
    text: 'Fraudes',
    icon: <WarningIcon />,
    subMenu: [
      { text: 'Règles', path: '/dash/fraudes/regles' },
      { text: 'Alertes', path: '/dash/fraudes/alertes' }
    ]
  },
  {
    text: 'Qualité',
    icon: <ReportIcon />,
    subMenu: [
      { text: 'Rapports', path: '/dash/qualite/rapports' }
    ]
  },
  {
    text: 'Risques',
    icon: <GavelIcon />,
    subMenu: [
      { text: 'Identification des Risques', path: '/dash/risques/identification' },
      { text: 'Gestion des Risques', path: '/dash/risques/gestion' },
      { text: 'Suivi des Risques', path: '/dash/risques/suivi' },
      { text: 'Audits et Conformité', path: '/dash/risques/audits' },
      { text: 'Plan de Continuité', path: '/dash/risques/continuite' },
      { text: 'Gestion des Incidents', path: '/dash/risques/incidents' }
    ]
  },
  {
    text: 'Taxes',
    icon: <BarChartIcon />,
    subMenu: [
      { text: 'Calcul', path: '/dash/taxes/calcul' },
      { text: 'Rapports', path: '/dash/taxes/rapports' }
    ]
  },
  {
    text: 'Certification',
    icon: <LockIcon />,
    subMenu: [
      { text: 'Enregistrement', path: '/dash/certif/enregistrement' },
      { text: 'Certification', path: '/dash/certif/certification' },
      { text: 'Révocation', path: '/dash/certif/revocation' },
      { text: 'Vérification', path: '/dash/certif/verif' }
    ]
  },
  {
    text: 'Statistiques',
    icon: <BarChartIcon />,
    subMenu: [
      { text: 'Vue Globale', path: '/dash/statistiques/global' },
      { text: 'Par Opérateurs', path: '/dash/statistiques/operateurs' },
      { text: 'Par Régions', path: '/dash/statistiques/regions' }
    ]
  }
];

const settingsItems = [
  {
    text: 'Config',
    icon: <SettingsIcon />,
    subMenu: [
      { text: 'Conf 1', path: '/dash/statistiques/global' },
      { text: 'Conf 2', path: '/dash/statistiques/operateurs' },
      { text: 'Conf 3', path: '/dash/statistiques/regions' }
    ]
  },
  {
    text: 'Utilisateurs',
    icon: <SettingsIcon />,
    subMenu: [
      { text: 'Visualiser', path: '/dash/users' },
      { text: 'Rôles', path: '/dash/roles' }
    ]
  }
];

export const MainListItems = () => {
  const [openMenus, setOpenMenus] = React.useState({});

  const handleClick = (menu) => {
    setOpenMenus(prevState => ({
      ...prevState,
      [menu]: !prevState[menu]
    }));
  };

  return (
    <React.Fragment>
      {menuItems.map((item) => (
        <React.Fragment key={item.text}>
          {item.subMenu ? (
            <React.Fragment>
              <ListItemButton onClick={() => handleClick(item.text)}>
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.text} />
                {openMenus[item.text] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </ListItemButton>
              <Collapse in={!!openMenus[item.text]} timeout="auto" unmountOnExit>
                {item.subMenu.map((subItem) => (
                  <ListItemButton
                    component={Link}
                    to={subItem.path}
                    key={subItem.text}
                     // Ajoute de l'indentation pour les sous-menus
                     sx={{ pl: 4, paddingTop:0, paddingBottom:0 }}
                  >
                    <ListItemText 
                    primary={subItem.text} 
                    />
                  </ListItemButton>
                ))}
              </Collapse>
            </React.Fragment>
          ) : (
            <ListItemButton component={Link} to={item.path}>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItemButton>
          )}
        </React.Fragment>
      ))}
    </React.Fragment>
  );
};

export const SecondaryListItems = () => 
{
  const [openMenus, setOpenMenus] = React.useState({});

  const handleClick = (menu) => {
    setOpenMenus(prevState => ({
      ...prevState,
      [menu]: !prevState[menu]
    }));
  };

  return  (
  <React.Fragment>
    <ListSubheader component="div" inset>
      Paramètres
    </ListSubheader>
    {settingsItems.map((item) => (
        <React.Fragment key={item.text}>
          {item.subMenu ? (
            <React.Fragment>
              <ListItemButton onClick={() => handleClick(item.text)}>
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.text} />
                {openMenus[item.text] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </ListItemButton>
              <Collapse in={!!openMenus[item.text]} timeout="auto" unmountOnExit>
                {item.subMenu.map((subItem) => (
                  <ListItemButton
                    component={Link}
                    to={subItem.path}
                    key={subItem.text}
                     // Ajoute de l'indentation pour les sous-menus
                     sx={{ pl: 4, paddingTop:0, paddingBottom:0 }}
                  >
                    <ListItemText 
                    primary={subItem.text} 
                    />
                  </ListItemButton>
                ))}
              </Collapse>
            </React.Fragment>
          ) : (
            <ListItemButton component={Link} to={item.path}>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItemButton>
          )}
        </React.Fragment>
      ))}
  </React.Fragment>
);}
