import React, { useState } from 'react';
import {
  Box, Typography, TextField, Table, TableBody, TableCell, TableContainer,
  TableHead, TableRow, Paper, Grid, Switch
} from '@mui/material';

// Exemple de données pour les rôles
const roleData = [
  { moduleName: 'Gestion des Utilisateurs', roleCode: 'USER_ADMIN', description: 'Accès complet à la gestion des utilisateurs', state: true },
  { moduleName: 'Gestion des Projets', roleCode: 'PROJECT_MANAGER', description: 'Responsable des projets', state: false },
  { moduleName: 'Suivi des Paiements', roleCode: 'PAYMENT_TRACKER', description: 'Suivi des paiements et des factures', state: true },
  // Ajoutez plus de rôles ici...
];

function RoleManagementUI() {
  const [roles, setRoles] = useState(roleData);

  // Fonction pour changer l'état d'un rôle (true/false)
  const handleRoleChange = (index) => {
    const updatedRoles = [...roles];
    updatedRoles[index].state = !updatedRoles[index].state; // Toggle between true and false
    setRoles(updatedRoles);
  };

  return (
    <Box sx={{ padding: 3 }}>
      {/* Profil de l'utilisateur */}
      <Box sx={{ marginBottom: 3 }}>
        <Typography variant="h6" gutterBottom sx={{fontSize: 16, textAlign: 'center', textTransform: 'uppercase', textDecoration: 'underline'}}>
          Détails du Profil Utilisateur
        </Typography>
        <Grid container spacing={2} marginTop={3}>
          <Grid item xs={12} sm={6}>
            <TextField fullWidth size='small' label="Nom" value="John Doe" variant="outlined" disabled />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField fullWidth size='small' label="Email" value="john.doe@example.com" variant="outlined" disabled />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField fullWidth size='small' label="Téléphone" value="+123456789" variant="outlined" disabled />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField fullWidth size='small' label="Adresse" value="123, Rue Exemple, Kinshasa" variant="outlined" disabled />
          </Grid>
        </Grid>
      </Box>

      {/* Compagnie de l'utilisateur */}
      <Box sx={{ marginBottom: 3 }}>
        <Typography variant="h6" gutterBottom sx={{fontSize: 16, textAlign: 'center', textTransform: 'uppercase', textDecoration: 'underline'}}>
          Compagnie de l'utilisateur
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField fullWidth size='small' label="Nom de la Compagnie" value="HorizonScript SARL" variant="outlined" disabled />
          </Grid>
          <Grid item xs={12}>
            <TextField fullWidth size='small' label="Adresse de la Compagnie" value="456, Avenue des Entreprises, Kinshasa" variant="outlined" disabled />
          </Grid>
        </Grid>
      </Box>

      {/* Table des rôles par module */}
      <Box>
        <Typography variant="h6" gutterBottom sx={{fontSize: 16, textAlign: 'center', textTransform: 'uppercase', textDecoration: 'underline'}}>
            Gestion des Rôles par Module
        </Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableHead style={{backgroundColor: '#DCDCDC'}}>
              <TableRow>
                <TableCell style={{paddingTop: '2px', paddingBottom: '2px'}}>Nom du Module</TableCell>
                <TableCell style={{paddingTop: '0px', paddingBottom: '0px'}}>Code du Rôle</TableCell>
                <TableCell style={{paddingTop: '0px', paddingBottom: '0px'}}>Description</TableCell>
                <TableCell style={{paddingTop: '0px', paddingBottom: '0px'}}>État</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {roles.map((role, index) => (
                <TableRow key={index}>
                  <TableCell style={{paddingTop: '0px', paddingBottom: '0px'}}>{role.moduleName}</TableCell>
                  <TableCell style={{paddingTop: '0px', paddingBottom: '0px'}}>{role.roleCode}</TableCell>
                  <TableCell style={{paddingTop: '0px', paddingBottom: '0px'}}>{role.description}</TableCell>
                  <TableCell style={{paddingTop: '0px', paddingBottom: '0px'}}>
                    <Switch
                      checked={role.state}
                      onChange={() => handleRoleChange(index)}
                      color="primary"
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
}

export default RoleManagementUI;
