/**
 * ACCESS ACTIONS
 */
export const SIGN_UP = 'SIGN_UP';
export const SIGN_IN = 'SIGN_IN';
export const SIGN_OUT = 'SIGN_OUT';
export const SIGN_FORGOT = 'SIGN_FORGOT';
export const SIGN_ERROR = 'SIGN_ERROR';

/**
 * APP ACTIONS
 */
export const APP_ADD = 'APP_ADD';
export const APP_EDIT = 'APP_EDIT';
export const APP_RETRIEVE = 'APP_RETRIEVE';
export const APP_FIND = 'APP_FIND';
export const APP_FIND_INIT = 'APP_FIND_INIT';
export const APP_ERROR = 'APP_ERROR';
export const APP_SCAN_SUCCESS = 'APP_SCAN_SUCCESS';
