import secureLocalStorage from "react-secure-storage";
import { BASE_URL } from "../configs/variable";

async function apiCreateDicoForUser(options) {
    return await fetch(BASE_URL.concat('/app/myapp'), options);
};

async function apiEditDicoForUser(options, uuid) {
    return await fetch(BASE_URL.concat('/app/myapp/'.concat(uuid)), options);
};

async function apiRetrieveDicoListForUser(options) {
    return await fetch(BASE_URL.concat('/app/myapp'), options);
};

async function apiRetrieveDicoForUser(options, uuid) {
    return await fetch(BASE_URL.concat('/app/myapp/'.concat(uuid)), options);
};

export const dicoAPI = {
    apiRetrieveDicoForUser,
    apiRetrieveDicoListForUser,
    apiCreateDicoForUser,
    apiEditDicoForUser
};